.cds-footer {
  bottom: 0;
  background-color: $color-white;
  font-size: $accessible-font-size-small;
  color: $color-grey-dark;
  left: 0;
  margin-top: 0;
  text-align: center;
  width: 100%;
  position: fixed;
  z-index: 1;
}

.cds-footer-info {
  position: relative;
}

// screen-medium
@media #{$screen-large} {
  .cds-footer {
    bottom: $cds-footer;
  }
}