// cds-card.scss
// cds-card


// cds-card
.cds-card {
  margin: ($spacing-small / 2);
  margin-top: 24px;
}

.ui-kit-cards>.cds-card {
  margin-top: 24px;
}

// screen-medium
@media #{$screen-medium} {

  // screen-medium:ui-kit-card
  .cds-card {
    margin: ($spacing-small / 2);
  }

}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-card
  .cds-card {
    margin: ($spacing-large / 2);
  }

}

.input-radio-card.input-radio-background label {
  transition: box-shadow .3s ease-in-out, background .3s ease-in-out, color .3s ease-in-out;
}

// input-radio-checked
.input-radio-card.input-radio-checked label {
  background: $color-accent;
  color: $color-white;
}