.cds-sticky {
    position: fixed;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    text-align: left;
    padding: 0 $spacing-small;
    z-index: 4;
    background: #f7f7fa;
    max-width: $size-medium * 2;
    margin: 0 auto;
    box-sizing: border-box;
}

.smooth-container {
    scroll-behavior: smooth
}

.current-child {
    height: 100%;
}

.form-container {
    padding: 20vh 0 40vh;
}

.form-container > div + div {
    margin-top: $spacing-large;
}

@media #{$screen-large} {
    .form-container > div + div {
        margin-top: ($spacing-medium * 2);
    }
}

.first-child {
    min-height: 300px;
}

// desktop or tablet
@media #{$screen-medium} {
    .form-container {
        padding: 40vh 0 40vh;
    }
}
