// _cds-step.scss

// cds-step-header
// cds-step-content
// cds-step-noneoftheabove


// cds-step-header
.cds-step-header {
  margin-bottom: $spacing-medium;
}

.cds-step-header h1 {
  font-family: $font-family-serif;
  font-size: $accessible-font-size-larger;
  font-weight: bold;
  line-height: round($accessible-font-size-larger * $ratio-medium);
  display: flex;
}

.cds-step-button .cds-buttons {
  font-size: $accessible-font-size-medium;
}

.cds-buttons .coverpage-button {
  font-size: $accessible-font-size-medium;
}

.cds-button-float .coverpage-button {
  font-size: $accessible-font-size-medium;
}

@media #{$screen-medium} {
  .cds-step-header h1 {
    font-size: $accessible-font-size-larger;
    line-height: round($accessible-font-size-larger * $ratio-medium);
  }
}

.cds-step-header .cds-step-number {
  padding-right: 8px;
}

.cds-resultview-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 80%;
}

// cds-step-content
.cds-step-content {
  display: flex;
  flex-wrap: wrap;
  margin: -($spacing-smaller);
}

.cds-step-content .input-text,
.cds-step-content .input-select,
.cds-step-content .input-date,
.cds-step-content .input-textarea,
.cds-step-content .input-radio.input-radio-card,
.cds-step-content .input-checkbox.input-checkbox-card {
  margin: $spacing-smaller;
  width: calc(100% - #{$spacing-small});
  display: flex;
  flex-direction: column;

  @media #{$screen-medium} {
    width: calc(50% - #{$spacing-small});
  }
}

.cds-step-content .input-radio.input-radio-card.input-radio-checked>label,
.cds-step-content .input-checkbox.input-checkbox-card.input-checkbox-checked>label {
  transition: box-shadow .3s ease-in-out, background .3s ease-in-out, color .3s ease-in-out;
  background: $color-accent;
  color: $color-white;
}

.cds-step-content .input-radio.input-radio-card>label,
.cds-step-content .input-checkbox.input-checkbox-card>label {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: $accessible-font-size-medium;
}

.cds-step-content .input-radio.input-radio-card+.input-radio.input-radio-card,
.cds-step-content .input-checkbox.input-checkbox-card+.input-checkbox.input-checkbox-card {
  margin-top: $spacing-smaller;
}

.cds-step-content .input-checkbox.input-checkbox-card.input-checkbox-checked input:checked+span::before {
  background-color: $color-white;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjNDc2OGZkIiBkPSJNMjMuMzcsNi4yOSwxMS45MywyMWEyLjg3LDIuODcsMCwwLDEtMy44Ni40NGwtMi0xLjYzTDEsMTQuNjVBMi42MiwyLjYyLDAsMCwxLC41NiwxMWEyLjU0LDIuNTQsMCwwLDEsMy41OC0uNDFsNiw1LjE3TDE5LjQ0LDNBMi41NCwyLjU0LDAsMCwxLDIzLDIuNTdhMi42MSwyLjYxLDAsMCwxLC4zMywzLjcyWiIvPjwvc3ZnPgo=');
}


// cds-step-noneoftheabove
.cds-step-noneoftheabove {
  width: 100%;
}