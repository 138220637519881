// _cds-label.scss


// 
.cds-label {

    display: inline-block;
    color: #32325D;
    font-family: $font-family-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 27px;
}

// screen-medium
@media #{$screen-medium} {

    // screen-medium:cds-label
    .cds-label {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 36px;
    }

}