.cds-logo-content {
    display: flex;
}

.cds-logo > img {
    width: 100px;
    height: 50px;
}

.cds-logo-description {
    font-size: $accessible-font-size-smedium;
    line-height: $accessible-font-size-larger * $ratio-small;
    font-family: $font-family-sans;
}

.cds-logo-description a {
    padding-right: round($spacing-smaller/2);
}

.cds-logo {
    padding-right: $spacing-small;
}