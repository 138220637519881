// _cds-resultview.scss

.cds-resultview-sub-header h3 {
  color: $color-grey-dark;
  font-family: $font-family-sans;
  font-size: $font-size-medium;
  font-weight: bold;
  line-height: round($ratio-medium * $font-size-medium);
  padding: $spacing-small 0;
}

.cds-resultview-subtitle h3 {
  color: $color-primary;
  font-family: $font-family-sans;
  font-size:$font-size-medium;
  font-weight: 900;
  line-height: round($ratio-medium * $font-size-medium);  
}

@media #{$screen-medium} {
  // screen-medium:cds-resultview-button
  .cds-resultview-button .ui-kit-button {
    width: 125px;
  }

  .cds-resultview-button-book .ui-kit-button {
    width: 230px;
  }

  .cds-resultview-subtitle h3 {
    font-size: $font-size-large;
    line-height: round($ratio-medium * $font-size-large);  
  }
}
