// _cds-header.scss

// cds-header


// cds-header
.cds-header {
  flex: 0 0 auto;
  margin-bottom: $spacing-large;
}
.cds-title-header h1 {
  font-size: $accessible-font-size-mediumlarge;
  font-weight: bold;
  line-height: round($ratio-medium * $font-size-large);
  font-family: $font-family-serif;
}
@media #{$screen-medium} {
  .cds-title-header h1 {
    font-size: $accessible-font-size-larger;
    line-height: round($ratio-medium * $font-size-larger);
    padding-bottom: $spacing-small;
  }
}

.cds-header h1 i {
  margin-left: $spacing-smaller;
}

.cds-header h1 svg * {
  color: $color-black;
}

.cds-header h4,
.cds-header p {
  margin-top: $spacing-small;
}
.cds-sub-header h3 {
  padding: $spacing-small 0 $spacing-smaller 0;
  font-size: $font-size-medium;
  font-weight: 900;
  line-height: round($ratio-medium * $font-size-medium);
}
@media #{$screen-medium} {
  .cds-sub-header h3 {
    font-size: $font-size-large;
    font-weight: 900;
    line-height: round($ratio-medium * $font-size-large);
  }
}
.cds-form-title {
  padding: $spacing-medium $spacing-smaller $spacing-small $spacing-smaller;
  font-size: $font-size-medium;
  font-weight: 900;
  line-height: round($ratio-medium * $font-size-medium);
  border-bottom: 1px solid $color-grey-light;
}
@media #{$screen-medium} {
  .cds-form-title {
    font-size: $font-size-large;
    font-weight: 900;
    line-height: $font-size-large;
    width: 752px;
  }
}
