// _cds-disclaimer.scss

.cds-disclaimer {
    border-left: 3px solid $color-secondary;
    padding-left: 13px; // non-standard
    margin-top: $spacing-small;

    strong {
        font-weight: 800;
    }
}