// _cds-content.scss

// cds-content
.cds-content p,
.cds-content .cds-content-p {
    color: $color-primary;
    font-family: $font-family-sans;
    font-size: $accessible-font-size-medium;
    line-height: round($ratio-medium * $font-size-medium);
    font-weight: 400;
}

.cds-content p {
    padding: 0 0 $spacing-smaller 0;
}

.cds-content,
.cds-content-p {
    padding: 0;
}

.cds-content+.cds-button-float {
    .cds-content p {
        padding-bottom: 80px;
    }
}

.cds-content-p ul {
    margin: $spacing-smaller 0 0;
}

.cds-content-p h1 {
    margin: $spacing-small 0;
}


.cds-error {
    color: $color-status-error;
    margin: 0 auto;
    padding: 2%;
}

.cds-content {
    padding-bottom: $spacing-small;
}

@media #{$screen-medium} {

    .cds-content p,
    .cds-content .cds-content-p {
        font-size: $accessible-font-size-mediumlarge;
        line-height: round($ratio-medium * $font-size-large);
    }

    .cds-content-booknow p {
        margin-top: $spacing-small;
        font-size: $font-size-medium;
    }
}