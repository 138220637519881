// _cds-wrapper.scss

// cds-wrapper
// screen-medium
// screen-medium:cds-wrapper


// cds-wrapper
.cds-wrapper {
  color: $color-primary;
  display: flex;
  flex-direction: column;
  font-size: $font-size-medium;
  height: 100%;
  line-height: round($font-size-medium * $ratio-medium);
  margin: 0 auto;
  max-width: $size-medium + $size-large;
  padding: $spacing-large $spacing-small $spacing-small;
  box-sizing: border-box;
}

.cds-wrapper label {
  padding: 0;
}

.cds-wrapper .input-checkbox span::before,
.cds-wrapper .input-radio span::before {
  border-color: $color-grey;
}

.cds-wrapper .input-date,
.cds-wrapper .input-text,
.cds-wrapper .input-select {
  max-width: $size-medium;
}

.cds-wrapper .input-textarea {
  max-width: $size-large;
}

.cds-wrapper .input-error,
.cds-wrapper .input-clear {
  padding-left: 0;
  padding-right: 0;
}

.cds-wrapper .input-clear {
  margin-top: $spacing-medium;
}

.cds-wrapper .form-item+.form-item {
  margin-top: $spacing-large;
}

.cds-wrapper .form-buttons {
  margin-top: $spacing-larger;
}

.form-container .page {
  max-width: $size-medium * 2;
  margin: 0 auto;
  opacity: 0.2;

  &.active {
    opacity: 1;
  }
}

.cds-buttons {
  margin: $spacing-medium 0 $spacing-smaller;

  @media #{$screen-medium} {
    width: 25%;
  }
}

.cds-wrapper .cds-loader {
  background-color: $color-grey-lighter;
}

.cds-body-content {
  padding-bottom: 80px;
}

@media #{$screen-medium} {
  .cds-body-content {
    padding-bottom: 0;
  }
}
@supports (-webkit-overflow-scrolling: touch) {
  /* CSS specific to iOS devices */
  html,
  body,
  #root {
    height: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
}