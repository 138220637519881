.cds-button-float {
    background: $color-white;
    border-top: 1px solid $color-grey-light;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 80px; 
    z-index: 100;
  }
  .cds-button-wrapper {
  padding: 16px;
  display: flex;
  flex-direction: column;
  }

.cds-submit-button {
  top: -$spacing-larger-viewheight;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  padding-top: $spacing-large;
  align-items: center;
}

.cds-submit-button .cds-button-content {
  font-size: $accessible-font-size-medium;
}