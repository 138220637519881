// cds.scss

// cds:dependencies
// ui-kit:base
// cds:wrappers
// cds:views

$accessible-font-size-small:                     0.75rem;       //12px
$accessible-font-size-smedium:                   0.875rem;      //14px
$accessible-font-size-medium:                    1rem;          //16px
$accessible-font-size-mediumlarge:               1.125rem;      //18px
$accessible-font-size-larger:                    1.5rem;        //24px
$cds-footer:                                     110px; 

// cds:dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';


// ui-kit:base
@import '@patient-access/ui-kit/styles/base/_base.scss';

$spacing-larger-viewheight:                     40vh;

// cds:wrappers
@import 'wrappers/cds-wrapper';
@import 'wrappers/cds-header';
@import 'wrappers/scroller';
@import 'wrappers/cds-button';
@import 'wrappers/cds-footer';
@import 'wrappers/cds-logo';
@import 'wrappers/cds-disclaimer';

// cds:views

@import 'views/cds-card';
@import 'views/cds-label';
@import 'views/cds-content';
@import 'views/cds-resultview';

@import './cds-step';

html {
  background: $color-grey-lighter;
  min-width: 0;
  font-size: 100%;
}

body {
  min-width: 0;
}